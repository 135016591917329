import { useEffect } from "react"

export function useScript(url?: string, options?: { onLoad: () => void; onError?: () => void }) {
  useEffect(() => {
    if (!url) return

    const script = document.createElement("script")

    script.src = url
    script.async = true

    if (options) {
      const { onLoad, onError } = options
      script.addEventListener("load", onLoad)
      if (onError) {
        script.addEventListener("error", onError)
      }
    }

    document.body.appendChild(script)

    // eslint-disable-next-line consistent-return
    return () => {
      window.WidgetThreadsStatus = undefined
      document.body.removeChild(script)
    }
  }, [url, options])
}
