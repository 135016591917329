import React, { useEffect, useState } from "react"

import Container from "@ecom/ui/components/Container"
import { navigate } from "gatsby"
import { Loader } from "../../Loader"
import { getToken } from "../api/api"
import { useScript } from "./helpers"
import { getIDBValue } from "../../../utils/idbUtils"
import "./threadsWidget.scss"

interface IClientData {
  phone: string
}

declare global {
  interface Window {
    WidgetThreadsStatus?: string
    WidgetThreads: {
      init: (config: {
        externalStyles: string
        mountElementId: string
        clientData: IClientData
        actions: {
          onReady: () => void
          onError: () => void
        }
      }) => void
      actions: {
        openChat: (isOpen: boolean) => void
      }
    }
  }
}

export function ThreadsWidget() {
  const [isLoad, setIsLoad] = useState(false)
  const [userPhone, setUserPhone] = useState("")
  const tokenUpload = getToken()

  useEffect(() => {
    getIDBValue<string>("phone").then((phone) => {
      if (phone) {
        setUserPhone(phone)
      }
    })
  }, [])

  useEffect(() => {
    if (!tokenUpload) {
      navigate("/error/")
    }
  }, [tokenUpload])

  useScript(process.env.GATSBY_APP_THREADSWIDJET, {
    onLoad: () => {
      window?.WidgetThreads.init({
        externalStyles: "./threadsWidget.scss",
        mountElementId: "threadsWidget",
        clientData: { phone: userPhone },
        actions: {
          onReady: () => {
            setIsLoad(true)
            window?.WidgetThreads.actions.openChat(true)
          },
          onError: () => {
            console.log("Loading error")
            navigate("/error/")
          },
        },
      })
    },
  })

  return (
    <section className="section">
      <Container className="container">
        <div id="threadsWidget" className="threadsWidget">
          <div>{!isLoad && <Loader />}</div>
        </div>
      </Container>
    </section>
  )
}
