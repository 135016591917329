import React from "react"

import { CircularProgress } from "../../icons"

import * as styles from "./loader.module.scss"

export function Loader() {
  return (
    <div className={styles.animation}>
      <CircularProgress />
    </div>
  )
}
